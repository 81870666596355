import {
  GET_CURRENT_USER_BEGIN,
  GET_CURRENT_USER_SUCCESS,
  GET_CURRENT_USER_FAILURE,
} from '../../actions/spotify/getCurrentUser';
import createReducer from '../createReducer';

const initialState = {
  user: {},
  loading: false,
  error: null,
};

const getCurrentUserBeginState = (state) => ({
  ...state,
  loading: true,
  error: null,
});

const getCurrentUserSuccessState = (state, action) => ({
  ...state,
  loading: false,
  user: action.payload.userData,
});

const getCurrentUserFailureState = (state, action) => ({
  ...state,
  loading: false,
  error: action.payload.error,
});

const getCurrentUser = createReducer(initialState, {
  [GET_CURRENT_USER_BEGIN]: getCurrentUserBeginState,
  [GET_CURRENT_USER_SUCCESS]: getCurrentUserSuccessState,
  [GET_CURRENT_USER_FAILURE]: getCurrentUserFailureState,
});

export default getCurrentUser;
