import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import ScheduleIcon from '@material-ui/icons/Schedule';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { createPurge } from '../../actions/purge/createPurge';
import { getActivePurge } from '../../actions/purge/getActivePurge';
import { addTracks } from '../../actions/purge/addTracks';
import usePrevious from '../../hooks/usePrevious';
import { ACTIVE_PLAYLIST } from '../../constants';
import './CreatePurge.scss';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const CreatePurge = (props) => {
  const classes = useStyles();

  const {
    open,
    handleClose,
    purge,
    loading,
    error,
    addTracksLoading,
    addTracksError,
    dispatch,
  } = props;

  const prevLoadingState = usePrevious(loading);
  const prevAddTracksLoadingState = usePrevious(addTracksLoading);

  const [purgeName, setPurgeName] = useState(null);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [openSnackbar, setOpenSnackBar] = useState(false);
  const [openErrorSnackbar, setOpenErrorSnackBar] = useState(false);

  useEffect(() => {
    if (prevLoadingState && !loading && error == null) {
      dispatch(addTracks(purge._id, ACTIVE_PLAYLIST));
    }
    if (prevLoadingState && !loading && error != null) {
      setOpenErrorSnackBar(true);
    }
  }, [loading]);

  useEffect(() => {
    if (prevAddTracksLoadingState && !addTracksLoading && addTracksError == null) {
      handleClose();
      setOpenSnackBar(true);
      dispatch(getActivePurge());
    }
  }, [addTracksLoading]);

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackBar(false);
  };

  const handleCloseErrorSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenErrorSnackBar(false);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleCreatePurge = () => {
    dispatch(
      createPurge(
        ACTIVE_PLAYLIST,
        purgeName,
        selectedDate,
      ),
    );
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        PaperProps={{
          style: {
            backgroundColor: '#2c154a',
            color: '#ff1db7',
          },
        }}
      >
        <DialogTitle id="form-dialog-title">Schedule Purge</DialogTitle>
        <DialogContent>
          <ol>
            <li>To schedule a purge enter a name and the date you want the purge to occur.</li>
            <li>Execution of purge will not be made available until said date.</li>
            <li>
              Any tracks that are in the playlist as of right now will be added to the
              purge (since the last purged track).
            </li>
            <li>
              Any tracks added to the playlist after scheduling a purge will not be subject to
              voting until the next scheduled purge.
            </li>
          </ol>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Purge Name"
            type="text"
            fullWidth
            onChange={(e) => setPurgeName(e.target.value)}
            className="CreatePurge__input"
          />
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="yyyy-MM-dd"
              margin="normal"
              id="date-picker-inline"
              label="Date picker inline"
              value={selectedDate}
              onChange={handleDateChange}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              className="CreatePurge__input"
            />
          </MuiPickersUtilsProvider>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <div className={classes.wrapper}>
            <Button
              variant="contained"
              onClick={handleCreatePurge}
              color="primary"
              disabled={loading || addTracksLoading}
              startIcon={<ScheduleIcon />}
            >
              Schedule
            </Button>
            {(loading || addTracksLoading) && <CircularProgress color="primary" size={24} className={classes.buttonProgress} />}
          </div>
        </DialogActions>
      </Dialog>
      <Snackbar open={openSnackbar} autoHideDuration={3000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity="success">
          Purge created successfully
        </Alert>
      </Snackbar>
      <Snackbar open={openErrorSnackbar} autoHideDuration={3000} onClose={handleCloseErrorSnackbar}>
        <Alert onClose={handleCloseErrorSnackbar} severity="error">
          {error ? (
            error.data
          ) : (
            'Error, please try again'
          )}
        </Alert>
      </Snackbar>
    </div>
  );
};

CreatePurge.propTypes = {
  dispatch: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  // from redux
  purge: PropTypes.shape({
    _id: PropTypes.string,
  }),
  loading: PropTypes.bool,
  error: PropTypes.shape({
    data: PropTypes.string,
  }),
  addTracksLoading: PropTypes.bool,
  addTracksError: PropTypes.shape({}),
};

CreatePurge.defaultProps = {
  // from redux
  purge: null,
  loading: false,
  error: null,
  addTracksLoading: null,
  addTracksError: null,
};

const mapStateToProps = (state) => ({
  // create purge state
  purge: state.purge.createPurge.purge,
  loading: state.purge.createPurge.loading,
  error: state.purge.createPurge.error,
  // add tracks state
  addTracksLoading: state.purge.addTracks.loading,
  addTracksError: state.purge.addTracks.error,
});

export default connect(mapStateToProps)(CreatePurge);
