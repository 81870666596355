import { combineReducers } from 'redux';
import playlists from './getPlaylists';
import tracks from './getTracks';
import allPlaylistTracks from './getAllPlaylistTracks';
import createPurge from './purge/createPurge';
import getCurrentUser from './spotify/getCurrentUser';
import getActivePurge from './purge/getActivePurge';
import addVote from './purge/addVote';
import addTracks from './purge/addTracks';
import deleteTracksFromPlaylist from './execution/deleteTracksFromPlaylist';
import getPurgeTrack from './tracks/getPurgeTrack';

export default combineReducers({
  playlists,
  tracks,
  allPlaylistTracks,
  purge: combineReducers({
    createPurge, getActivePurge, addVote, addTracks,
  }),
  currentUser: getCurrentUser,
  execution: combineReducers({ deleteTracksFromPlaylist }),
  purgeTrack: getPurgeTrack,
});
