import React from 'react';
import './App.scss';
import queryString from 'query-string';
import Playlists from './components/Playlists';
import SignInButton from './components/SignInButton';

const App = () => {
  const hasAccessToken = queryString.parse(window.location.search).access_token;

  return (
    <div id="App" className="App">
      {hasAccessToken ? (
        <Playlists />
      ) : (
        <SignInButton />
      )}
    </div>
  );
};

export default App;
