import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Backdrop from '@material-ui/core/Backdrop';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import PropTypes from 'prop-types';
import Fuse from 'fuse.js';
import { StylesProvider, makeStyles } from '@material-ui/core/styles';

import './Search.scss';
import { getAllTracksForEveryPlaylist } from '../actions';
import LoadingSpinner from './LoadingSpinner';

const useStyles = makeStyles((theme) => ({
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
}));

const createData = (title, artist, album) => ({ title, artist, album });

const Search = (props) => {
  const {
    playlists,
    allPlaylistTracks,
    loading,
    dispatch,
  } = props;

  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [rows, setRows] = useState([]);
  const [filteredSongs, setFilteredSongs] = useState([]);
  const [searchWord, setSearchWord] = useState('');
  const [showNoResults, setShowNoResults] = useState(false);

  // on mount
  useEffect(() => {
    dispatch(getAllTracksForEveryPlaylist(playlists));
  }, []);

  useEffect(() => {
    const result = filteredSongs
      .map(
        (fuseItem) => createData(fuseItem.item.track.name,
          fuseItem.item.track.artists[0].name,
          fuseItem.item.track.album.name),
      );
    setRows(result);
  }, [filteredSongs]);

  useEffect(() => {
    if (open) {
      document.getElementById('search-input').focus();
    }
  }, [open]);

  const handleClose = () => {
    setRows([]);
    setFilteredSongs([]);
    setSearchWord('');
    setShowNoResults(false);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen(!open);
  };

  const handleSearch = () => {
    const options = {
      includeScore: true,
      minMatchCharLength: 2,
      threshold: 0.15,
      keys: ['track.name', 'track.artists.name', 'track.album.name'],
    };

    const fuse = new Fuse(allPlaylistTracks, options);
    const fuseResults = fuse.search(searchWord);
    setFilteredSongs(fuseResults);

    if (fuseResults.length === 0) {
      setShowNoResults(true);
    } else {
      setShowNoResults(false);
    }
  };

  const handleKeyUpEvent = (event) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  return (
    <div>
      <StylesProvider injectFirst>
        {!open ? (
          <IconButton className="Search__search-btn" onClick={handleToggle}>
            <SearchIcon fontSize="large" />
          </IconButton>
        ) : null}
        {!loading ? (
          <Backdrop className={classes.backdrop} open={open}>
            <IconButton className="Search__search-btn" onClick={handleClose}>
              <CloseIcon fontSize="large" />
            </IconButton>
            <Paper className="Search__search-container">
              <div className="Search__search-input">
                <InputBase
                  id="search-input"
                  className={classes.input}
                  placeholder="Search Songs"
                  value={searchWord}
                  onChange={(e) => setSearchWord(e.target.value)}
                  onKeyUp={(e) => handleKeyUpEvent(e)}
                />
                <IconButton className="Search__icon-button" aria-label="search" onClick={handleSearch}>
                  <SearchIcon />
                </IconButton>
              </div>
              {showNoResults ? (
                <p className="Search__no-results">No results</p>
              ) : null}
            </Paper>
            {rows.length > 0 ? (
              <div className="Search__table-container">
                <TableContainer component={Paper}>
                  <Table className="Search__table" aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell><b>Title</b></TableCell>
                        <TableCell align="left"><b>Artist</b></TableCell>
                        <TableCell align="left"><b>Album</b></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows.map((row) => (
                        <TableRow key={row.title}>
                          <TableCell component="th" scope="row">
                            {row.title}
                          </TableCell>
                          <TableCell align="left">{row.artist}</TableCell>
                          <TableCell align="left">{row.album}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            ) : null}
          </Backdrop>
        ) : <LoadingSpinner />}
      </StylesProvider>
    </div>
  );
};

Search.propTypes = {
  dispatch: PropTypes.func.isRequired,
  allPlaylistTracks: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.shape({}),
  playlists: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

Search.defaultProps = {
  error: null,
};

const mapStateToProps = (state) => ({
  allPlaylistTracks: state.allPlaylistTracks.allTracks,
  loading: state.allPlaylistTracks.loading,
  error: state.allPlaylistTracks.error,
});

export default connect(mapStateToProps)(Search);
