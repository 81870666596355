import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import './LoadingSpinner.scss';

const LoadingSpinner = () => (
  <div className="LoadingSpinner">
    <CircularProgress className="LoadingSpinner--white" />
  </div>
);

export default LoadingSpinner;
