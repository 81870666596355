import {
  CREATE_PURGE_BEGIN,
  CREATE_PURGE_SUCCESS,
  CREATE_PURGE_FAILURE,
} from '../../actions/purge/createPurge';
import createReducer from '../createReducer';

const initialState = {
  purge: {},
  loading: false,
  error: null,
};

const createPurgeBeginState = (state) => ({
  ...state,
  loading: true,
  error: null,
});

const createPurgeSuccessState = (state, action) => ({
  ...state,
  loading: false,
  purge: action.payload.purgeData,
});

const createPurgeFailureState = (state, action) => ({
  ...state,
  loading: false,
  error: action.payload.error,
});

const createPurge = createReducer(initialState, {
  [CREATE_PURGE_BEGIN]: createPurgeBeginState,
  [CREATE_PURGE_SUCCESS]: createPurgeSuccessState,
  [CREATE_PURGE_FAILURE]: createPurgeFailureState,
});

export default createPurge;
