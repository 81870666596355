import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import CloseIcon from '@material-ui/icons/Close';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbUpOutlinedIcon from '@material-ui/icons/ThumbUpOutlined';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import ThumbDownOutlinedIcon from '@material-ui/icons/ThumbDownOutlined';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Slide from '@material-ui/core/Slide';
import { utcToZonedTime, format } from 'date-fns-tz';
import { addVote } from '../../actions/purge/addVote';
import { deleteTracksFromPlaylist } from '../../actions/execution/deleteTracksFromPlaylist';
import usePrevious from '../../hooks/usePrevious';
import UserVotes from './UserVotes';
import './PurgeTracks.scss';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const useStyles = makeStyles((theme) => ({
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  button: {
    fontWeight: 'bold',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const PurgeTracks = (props) => {
  const classes = useStyles();

  const {
    open,
    activePurge,
    addVoteLoading,
    addVoteError,
    handleClose,
    deleteLoading,
    deleteError,
    currentUserId,
    dispatch,
  } = props;

  const prevVoteLoadingState = usePrevious(addVoteLoading);
  const prevDeleteLoadingState = usePrevious(deleteLoading);

  const [userTrackVotes, setUserTrackVotes] = useState({});
  const [openSnackbar, setOpenSnackBar] = useState(false);
  const [openPurgeSuccessSnackbar, setOpenPurgeSuccessSnackbar] = useState(false);
  const [openPurgeErrorSnackbar, setOpenPurgeErrorSnackbar] = useState(false);
  const [cstDate, setCstDate] = useState(new Date(activePurge.date));
  const [openUserVotes, setOpenUserVotes] = useState(false);
  const [purgeTrackId, setPurgeTrackId] = useState('');

  // on mount
  useEffect(() => {
    const filteredUserVotes = activePurge.tracks
      .filter((track) => track.votes.some((vote) => vote.spotifyUserId === currentUserId));

    const userVotes = filteredUserVotes.reduce((map, filteredTracks) => ({
      ...map,
      [filteredTracks._id]: filteredTracks.votes
        .find((vote) => vote.spotifyUserId === currentUserId).liked,
    }), {});

    setUserTrackVotes(userVotes);

    // convert utc date to cst
    const timeZone = 'Europe/Berlin';
    const zonedDate = utcToZonedTime(cstDate, timeZone);
    setCstDate(zonedDate);
  }, []);

  useEffect(() => {
    if (prevVoteLoadingState && !addVoteLoading && addVoteError != null) {
      setOpenSnackBar(true);
    }
  }, [addVoteLoading]);

  useEffect(() => {
    if (prevDeleteLoadingState && !deleteLoading && deleteError == null) {
      setOpenPurgeSuccessSnackbar(true);
    } else if (prevDeleteLoadingState && !deleteLoading && deleteError != null) {
      setOpenPurgeErrorSnackbar(true);
    }
  }, [deleteLoading]);

  const handleVote = (trackId, liked) => {
    const updatedUserVotes = userTrackVotes;
    updatedUserVotes[trackId] = liked;

    setUserTrackVotes(updatedUserVotes);

    dispatch(addVote(
      activePurge._id,
      trackId,
      currentUserId,
      liked,
    ));
  };

  const handleDeleteTracks = () => {
    dispatch(deleteTracksFromPlaylist(
      activePurge._id,
      activePurge.playlistId,
    ));
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackBar(false);
  };

  const handleClosePurgeSuccessSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenPurgeSuccessSnackbar(false);
  };

  const handleClosePurgeErrorSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenPurgeErrorSnackbar(false);
  };

  const handleTrackClick = (trackId) => {
    setPurgeTrackId(trackId);
    setOpenUserVotes(true);
  };

  return (
    <div>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        scroll="paper"
        fullWidth
        PaperProps={{
          style: {
            textAlign: 'center',
            backgroundColor: '#2c154a',
            color: '#ff1db7',
          },
        }}
        TransitionComponent={Transition}
      >
        <DialogTitle>{activePurge.name}</DialogTitle>
        <IconButton onClick={handleClose} className="PurgeTracks__close-btn">
          <CloseIcon />
        </IconButton>
        <DialogContent className="PurgeTracks__content">
          <DialogContentText className="PurgeTracks__subtext">
            {format(cstDate, 'MMM dd, yyyy')}
          </DialogContentText>
          {activePurge.readyToPurge ? (
            <div className={classes.wrapper}>
              <Button
                className={classes.button}
                variant="contained"
                onClick={handleDeleteTracks}
                color="primary"
                disabled={deleteLoading}
              >
                Run Purge
              </Button>
              {deleteLoading && <CircularProgress color="primary" size={24} className={classes.buttonProgress} />}
            </div>
          ) : null}
          <List>
            {activePurge.tracks.map((track) => (
              <ListItem
                key={track._id}
                className="PurgeTracks__list-item"
                button
                onClick={() => handleTrackClick(track._id)}
              >
                <ListItemAvatar>
                  <Avatar variant="square" alt={track.name} src={track.albumImage} />
                </ListItemAvatar>
                <ListItemText
                  className="PurgeTracks__track-name"
                  primary={track.name}
                />
                <ListItemSecondaryAction>
                  <div>
                    <IconButton
                      className="PurgeTracks__vote-btn"
                      onClick={() => handleVote(track._id, false)}
                    >
                      {(track._id in userTrackVotes && !userTrackVotes[track._id]) ? (
                        <ThumbDownIcon style={{ color: 'red' }} />
                      ) : (
                        <ThumbDownOutlinedIcon />
                      )}
                    </IconButton>
                    <IconButton
                      className="PurgeTracks__vote-btn"
                      onClick={() => handleVote(track._id, true)}
                    >
                      {(track._id in userTrackVotes && userTrackVotes[track._id]) ? (
                        <ThumbUpIcon style={{ color: 'green' }} />
                      ) : (
                        <ThumbUpOutlinedIcon />
                      )}
                    </IconButton>
                  </div>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </DialogContent>
      </Dialog>
      <Snackbar open={openSnackbar} autoHideDuration={3500} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity="error">
          Error casting vote
        </Alert>
      </Snackbar>
      <Snackbar
        open={openPurgeSuccessSnackbar}
        autoHideDuration={3500}
        onClose={handleClosePurgeSuccessSnackbar}
      >
        <Alert onClose={handleClosePurgeSuccessSnackbar} severity="success">
          Tracks Purged Successfully
        </Alert>
      </Snackbar>
      <Snackbar
        open={openPurgeErrorSnackbar}
        autoHideDuration={3500}
        onClose={handleClosePurgeErrorSnackbar}
      >
        <Alert onClose={handleClosePurgeErrorSnackbar} severity="error">
          {deleteError?.data?.error?.message}
        </Alert>
      </Snackbar>
      <UserVotes
        open={openUserVotes}
        handleClose={() => setOpenUserVotes(false)}
        trackId={purgeTrackId}
      />
    </div>
  );
};

PurgeTracks.propTypes = {
  dispatch: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  activePurge: PropTypes.shape({
    _id: PropTypes.string,
    name: PropTypes.string,
    tracks: PropTypes.arrayOf(PropTypes.shape({})),
    date: PropTypes.string,
    playlistId: PropTypes.string,
    readyToPurge: PropTypes.bool,
  }).isRequired,
  currentUserId: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  // from redux
  addVoteLoading: PropTypes.bool,
  addVoteError: PropTypes.shape({
    data: PropTypes.string,
  }),
  deleteLoading: PropTypes.bool,
  deleteError: PropTypes.shape({
    data: PropTypes.shape({
      error: PropTypes.shape({
        message: PropTypes.string,
      }),
    }),
  }),
};

PurgeTracks.defaultProps = {
  addVoteLoading: false,
  addVoteError: null,
  deleteLoading: false,
  deleteError: null,
};

const mapStateToProps = (state) => ({
  addVoteLoading: state.purge.addVote.loading,
  addVoteError: state.purge.addVote.error,
  currentUserId: state.currentUser.user.id,
  deleteLoading: state.execution.deleteTracksFromPlaylist.loading,
  deleteError: state.execution.deleteTracksFromPlaylist.error,
});

export default connect(mapStateToProps)(PurgeTracks);
