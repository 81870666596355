import {
  GET_PLAYLISTS_BEGIN,
  GET_PLAYLISTS_SUCCESS,
  GET_PLAYLISTS_FAILURE,
} from '../actions';

const initialState = {
  playlists: [],
  loading: true, // TODO: inital state was causing error check it out again
  error: null,
};

const getPlaylists = (state = initialState, action) => {
  switch (action.type) {
    case GET_PLAYLISTS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_PLAYLISTS_SUCCESS:
      return {
        ...state,
        loading: false,
        playlists: action.payload.playlists,
      };
    case GET_PLAYLISTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error.message,
        playlists: [],
      };
    default:
      return state;
  }
};

export default getPlaylists;
