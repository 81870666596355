import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Chart from 'chart.js';

const LineChart = (props) => {
  const {
    title,
    data,
    color,
    dataSetLabel,
  } = props;

  const [chart, setChart] = useState(null);
  const chartRef = useRef(null);

  useEffect(() => {
    if (chart != null) {
      chart.destroy();
    }

    setChart(new Chart(chartRef.current, {
      type: 'line',
      options: {
        scales: {
          xAxes: [{
            display: true,
            scaleLabel: {
              display: true,
              labelString: 'Month',
              fontColor: color,
            },
            ticks: {
              fontColor: color,
            },
          }],
          yAxes: [{
            ticks: {
              fontColor: color,
              min: 0,
            },
          }],
        },
        legend: {
          display: false,
        },
        title: {
          display: true,
          text: title,
          fontSize: '15',
          fontColor: color,
        },
        maintainAspectRatio: false,
      },
      data: {
        labels: Object.keys(data).map((key) => key),
        datasets: [{
          label: dataSetLabel,
          data: Object.keys(data).map((key) => data[key]),
          fill: 'none',
          backgroundColor: '#ff1db7',
          pointRadius: 8,
          pointHoverRadius: 7,
          borderColor: '#3a205b',
          borderWidth: 1,
          lineTension: 0,
        }],
      },
    }));
  }, [data]);

  return <canvas ref={chartRef} />;
};

LineChart.propTypes = {
  data: PropTypes.shape().isRequired,
  dataSetLabel: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
};

export default LineChart;
