import {
  ADD_TRACKS_BEGIN,
  ADD_TRACKS_SUCCESS,
  ADD_TRACKS_FAILURE,
} from '../../actions/purge/addTracks';
import createReducer from '../createReducer';

const initialState = {
  successMessage: null,
  loading: false,
  error: null,
};

const addTracksBeginState = (state) => ({
  ...state,
  loading: true,
  error: null,
});

const addTracksSuccessState = (state, action) => ({
  ...state,
  loading: false,
  successMessage: action.payload.successMessage,
});

const addTracksFailureState = (state, action) => ({
  ...state,
  loading: false,
  error: action.payload.error,
});

const addTracks = createReducer(initialState, {
  [ADD_TRACKS_BEGIN]: addTracksBeginState,
  [ADD_TRACKS_SUCCESS]: addTracksSuccessState,
  [ADD_TRACKS_FAILURE]: addTracksFailureState,
});

export default addTracks;
