import React, { useEffect, useRef, useState } from 'react';
import Chart from 'chart.js';
import PropTypes from 'prop-types';

const DoughnutChart = (props) => {
  const {
    title,
    data,
    colors,
  } = props;

  const [chart, setChart] = useState(null);
  const chartRef = useRef(null);

  useEffect(() => {
    if (chart != null) {
      chart.destroy();
    }

    setChart(new Chart(chartRef.current, {
      type: 'doughnut',
      options: {
        title: {
          display: true,
          text: title,
          fontSize: '15',
          fontColor: '#383838',
        },
        legend: {
          labels: {
            fontColor: '#383838',
          },
        },
        maintainAspectRatio: false,
      },
      data: {
        labels: Object.keys(data).map((key) => key),
        datasets: [{
          data: Object.keys(data).map((key) => data[key]),
          backgroundColor: colors,
          borderWidth: 1,
        }],
      },
    }));
  }, [data]);

  return <canvas ref={chartRef} />;
};

DoughnutChart.propTypes = {
  data: PropTypes.shape({}).isRequired,
  title: PropTypes.string.isRequired,
  colors: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default DoughnutChart;
