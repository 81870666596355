import axios from 'axios';

export const GET_ACTIVE_PURGE_BEGIN = 'GET_ACTIVE_PURGE_BEGIN';
export const GET_ACTIVE_PURGE_SUCCESS = 'GET_ACTIVE_PURGE_SUCCESS';
export const GET_ACTIVE_PURGE_FAILURE = 'GET_ACTIVE_PURGE_FAILURE';

export const getActivePurgeBegin = () => ({
  type: GET_ACTIVE_PURGE_BEGIN,
});

export const getActivePurgeSuccess = (purgeData) => ({
  type: GET_ACTIVE_PURGE_SUCCESS,
  payload: { purgeData },
});

export const getActivePurgeError = (error) => ({
  type: GET_ACTIVE_PURGE_SUCCESS,
  payload: { error },
});

export const getActivePurge = () => (dispatch) => {
  dispatch(getActivePurgeBegin());

  return axios
    .get('/api/purge')
    .then((response) => response.data)
    .then((purgeData) => dispatch(getActivePurgeSuccess(purgeData)))
    .catch((error) => dispatch(getActivePurgeError(error.response)));
};
