import {
  ADD_VOTE_BEGIN,
  ADD_VOTE_SUCCESS,
  ADD_VOTE_FAILURE,
} from '../../actions/purge/addVote';
import createReducer from '../createReducer';

const initialState = {
  successMessage: null,
  loading: false,
  error: null,
};

const addVoteBeginState = (state) => ({
  ...state,
  loading: true,
  error: null,
});

const addVoteSuccessState = (state, action) => ({
  ...state,
  loading: false,
  successMessage: action.payload.successMessage,
});

const addVoteFailureState = (state, action) => ({
  ...state,
  loading: false,
  error: action.payload.error,
});

const addVote = createReducer(initialState, {
  [ADD_VOTE_BEGIN]: addVoteBeginState,
  [ADD_VOTE_SUCCESS]: addVoteSuccessState,
  [ADD_VOTE_FAILURE]: addVoteFailureState,
});

export default addVote;
