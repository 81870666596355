import queryString from 'query-string';

// action types
// TODO: move these to a constants file/folder
export const GET_PLAYLISTS_BEGIN = 'GET_PLAYLISTS_BEGIN';
export const GET_PLAYLISTS_SUCCESS = 'GET_PLAYLISTS_SUCCESS';
export const GET_PLAYLISTS_FAILURE = 'GET_PLAYLISTS_FAILURE';
export const GET_TRACKS_BEGIN = 'GET_TRACKS_BEGIN';
export const GET_TRACKS_SUCCESS = 'GET_TRACKS_SUCCESS';
export const GET_TRACKS_FAILURE = 'GET_TRACKS_FAILURE';
export const GET_ALL_PLAYLIST_TRACKS_BEGIN = 'GET_ALL_PLAYLIST_TRACKS_BEGIN';
export const GET_ALL_PLAYLIST_TRACKS_SUCCESS = 'GET_ALL_PLAYLIST_TRACKS_SUCCESS';
export const GET_ALL_PLAYLIST_TRACKS_FAILURE = 'GET_ALL_PLAYLIST_TRACKS_FAILURE';

const summervibesCollection = [
  '7ySQtq3A6SZbTglbgmkVX5',
  '738vz2334eEGLr0ptFJEIt',
  '4x83pAg1IGX1JYpXGPoWoK',
  '08raFRtgjwu17YwZOSpxwL',
  '65abE0t7yf5pvm8aHtzbFw',
  '1j3t7OA4Gj4a80u2hhwz0g',
  '0yVoYQluIXCc7GfD4XzQfk',
  '2gPCJ96kl2sr4yHcrcMZSw',
];

export const fetchPlaylistsBegin = () => ({
  type: GET_PLAYLISTS_BEGIN,
});

export const fetchPlaylistsSuccess = (playlists) => ({
  type: GET_PLAYLISTS_SUCCESS,
  payload: { playlists },
});

export const fetchPlaylistsError = (error) => ({
  type: GET_PLAYLISTS_FAILURE,
  payload: error,
});

export const fetchTracksBegin = () => ({
  type: GET_TRACKS_BEGIN,
});

export const fetchTracksSuccess = (tracks) => ({
  type: GET_TRACKS_SUCCESS,
  payload: { tracks },
});

export const fetchTracksError = (error) => ({
  type: GET_TRACKS_FAILURE,
  payload: { error },
});

export const fetchAllPlaylistTracksBegin = () => ({
  type: GET_ALL_PLAYLIST_TRACKS_BEGIN,
});

export const fetchAllPlaylistTracksSuccess = (tracks) => ({
  type: GET_ALL_PLAYLIST_TRACKS_SUCCESS,
  payload: { tracks },
});

export const fetchAllPlaylistTracksError = (error) => ({
  type: GET_ALL_PLAYLIST_TRACKS_FAILURE,
  payload: { error },
});

// actions
const handleHttpErrors = (response) => {
  if (!response.ok) {
    throw response;
  }
  return response.json();
};

const getPlaylist = (playlistUri) => {
  const parsed = queryString.parse(window.location.search);
  const accessToken = parsed.access_token;

  return new Promise((resolve, reject) => {
    fetch(`https://api.spotify.com/v1/playlists/${playlistUri}`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    }).then(handleHttpErrors)
      .then((json) => resolve(json))
      .catch((error) => {
        error.json().then((errorMessage) => {
          reject(errorMessage);
        });
      });
  });
};

export const getPlaylists = () => {
  const playlists = [];

  return (dispatch) => {
    dispatch(fetchPlaylistsBegin());
    summervibesCollection.forEach((playlistUri) => {
      playlists.push(getPlaylist(playlistUri));
    });

    Promise.all(playlists)
      .then((allPlaylistData) => {
        dispatch(fetchPlaylistsSuccess(allPlaylistData));
        return allPlaylistData;
      })
      .catch((error) => dispatch(fetchPlaylistsError(error)));
  };
};

const getNext100Tracks = async (apiUrl) => {
  const parsed = queryString.parse(window.location.search);
  const accessToken = parsed.access_token;

  return fetch(apiUrl, {
    headers: { Authorization: `Bearer ${accessToken}` },
  }).then((response) => response.json())
    .then((tracks) => tracks);
};

const getPlaylistTracks = async (playlist) => {
  const allTracks = [];
  allTracks.push(...playlist.tracks.items);

  let partialTrackList = playlist.tracks;

  /* eslint-disable no-await-in-loop */
  while (partialTrackList.next) {
    const next100 = await getNext100Tracks(partialTrackList.next)
      .catch((error) => {
        throw error;
      });
    allTracks.push(...next100.items);
    partialTrackList = next100;
  }
  /* eslint-enable no-await-in-loop */

  return allTracks;
};

export const getAllTracksForEveryPlaylist = (playlists) => {
  const tracks = [];

  return (dispatch) => {
    dispatch(fetchAllPlaylistTracksBegin());

    playlists.forEach((playlist) => {
      getPlaylistTracks(playlist)
        .then((playlistTracks) => {
          tracks.push(...playlistTracks);
        })
        .catch((error) => {
          dispatch(fetchAllPlaylistTracksError(error));
          return error;
        });
    });

    dispatch(fetchAllPlaylistTracksSuccess(tracks));
    return tracks;
  };
};

export const getTracks = (playlist) => {
  const allTracks = [];
  allTracks.push(...playlist.tracks.items);

  let partialTrackList = playlist.tracks;

  return async (dispatch) => {
    dispatch(fetchTracksBegin());

    /* eslint-disable no-await-in-loop */
    while (partialTrackList.next) {
      const next100 = await getNext100Tracks(partialTrackList.next)
        .catch((error) => {
          dispatch(fetchTracksError(error));
          return error;
        });
      allTracks.push(...next100.items);
      partialTrackList = next100;
    }
    /* eslint-enable no-await-in-loop */

    dispatch(fetchTracksSuccess(allTracks));
    return allTracks;
  };
};
