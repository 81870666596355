import {
  GET_ACTIVE_PURGE_BEGIN,
  GET_ACTIVE_PURGE_SUCCESS,
  GET_ACTIVE_PURGE_FAILURE,
} from '../../actions/purge/getActivePurge';
import createReducer from '../createReducer';

const initialState = {
  purge: {},
  loading: true,
  error: null,
};

const getActivePurgeBeginState = (state) => ({
  ...state,
  loading: true,
  error: null,
});

const getActivePurgeSuccessState = (state, action) => ({
  ...state,
  loading: false,
  purge: action.payload.purgeData,
});

const getActivePurgeFailureState = (state, action) => ({
  ...state,
  loading: false,
  error: action.payload.error,
});

const getActivePurge = createReducer(initialState, {
  [GET_ACTIVE_PURGE_BEGIN]: getActivePurgeBeginState,
  [GET_ACTIVE_PURGE_SUCCESS]: getActivePurgeSuccessState,
  [GET_ACTIVE_PURGE_FAILURE]: getActivePurgeFailureState,
});

export default getActivePurge;
