import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import DialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';
import CircularProgress from '@material-ui/core/CircularProgress';
import PurgeTracks from './PurgeTracks';
import CreatePurge from './CreatePurge';
import { getActivePurge } from '../../actions/purge/getActivePurge';

const Purge = (props) => {
  const {
    open,
    handleClose,
    activePurge,
    purgeLoading,
    dispatch,
  } = props;

  useEffect(() => {
    dispatch(getActivePurge());
  }, []);

  return (
    <div>
      <CreatePurge open={open && !purgeLoading && activePurge == null} handleClose={handleClose} />
      {!purgeLoading && activePurge != null ? (
        <PurgeTracks
          open={open}
          activePurge={activePurge}
          handleClose={handleClose}
        />
      ) : (
        <Dialog
          open={open && activePurge != null}
          onClose={handleClose}
          scroll="paper"
          fullWidth
          PaperProps={{
            style: {
              textAlign: 'center',
              backgroundColor: '#2c154a',
              color: '#ff1db7',
            },
          }}
        >
          <DialogContent className="PurgeTracks__content">
            <CircularProgress color="primary" />
          </DialogContent>
        </Dialog>
      )}
    </div>
  );
};

Purge.propTypes = {
  dispatch: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  // from redux
  activePurge: PropTypes.shape({
    _id: PropTypes.string,
    name: PropTypes.string,
  }),
  purgeLoading: PropTypes.bool,
  purgeError: PropTypes.shape({
    data: PropTypes.string,
  }),
};

Purge.defaultProps = {
  activePurge: null,
  purgeLoading: false,
  purgeError: null,
};

const mapStateToProps = (state) => ({
  activePurge: state.purge.getActivePurge.purge,
  purgeLoading: state.purge.getActivePurge.loading,
  purgeError: state.purge.getActivePurge.error,
});

export default connect(mapStateToProps)(Purge);
