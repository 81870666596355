const MONTH_NAMES = [
  'January', 'February',
  'March', 'April',
  'May', 'June',
  'July', 'August',
  'September', 'October',
  'November', 'December',
];

export const NAME_MAPPINGS = {
  '21uvkdul3guzsog44gxglkria': 'Connor',
  '21ywthxekhstvkadlpgnymvyy': 'Nick',
  bfindlater46: 'Brett',
  djwhqest: 'Albert',
  russellhewlett: 'Russ',
  thebraedog: 'Braeden',
  frdergf456yxdvt: 'Carter',
  nvthomson1: 'Dylan',
};

export const getSongsAddedByMonth = (tracks) => {
  const numberOfSongsAddedPerMonth = {};
  tracks.forEach((track) => {
    const date = new Date(track.added_at);
    const month = MONTH_NAMES[date.getMonth()];

    if (month in numberOfSongsAddedPerMonth) {
      numberOfSongsAddedPerMonth[month]++;
    } else {
      numberOfSongsAddedPerMonth[month] = 1;
    }
  });

  return numberOfSongsAddedPerMonth;
};

export const getNumberOfAddsPerPerson = (tracks) => {
  const numberOfAddsPerPerson = {};
  tracks.forEach((track) => {
    const person = NAME_MAPPINGS[track.added_by.id]
      ? NAME_MAPPINGS[track.added_by.id] : track.added_by.id;

    if (person in numberOfAddsPerPerson) {
      numberOfAddsPerPerson[person]++;
    } else {
      numberOfAddsPerPerson[person] = 1;
    }
  });

  return numberOfAddsPerPerson;
};

export const getArtists = (tracks) => {
  const numberOfSongsPerArtist = {};

  tracks.forEach((track) => {
    (track.track.artists).forEach((artist) => {
      if (artist.name in numberOfSongsPerArtist) {
        numberOfSongsPerArtist[artist.name]++;
      } else {
        numberOfSongsPerArtist[artist.name] = 1;
      }
    });
  });

  // Create array of artists and the number of songs
  const top5Artists = Object.keys(numberOfSongsPerArtist)
    .map((key) => [key, numberOfSongsPerArtist[key]]);

  // Sort the array based on the number of songs for each artist
  top5Artists.sort((first, second) => second[1] - first[1]);

  // Create a new array with only the top 5 artists
  return top5Artists.slice(0, 5);
};

export const getPlaylistDuration = (tracks) => {
  let duration = 0;

  tracks.forEach((track) => {
    duration += track.track.duration_ms;
  });

  let minutes = Math.floor((duration / (1000 * 60)) % 60);
  let hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

  hours = (hours < 10) ? `0${hours}` : hours;
  minutes = (minutes < 10) ? `0${minutes}` : minutes;

  return `${hours} hr ${minutes} min `;
};
