import axios from 'axios';

export const GET_TRACK_BEGIN = 'GET_TRACK_BEGIN';
export const GET_TRACK_SUCCESS = 'GET_TRACK_SUCCESS';
export const GET_TRACK_FAILURE = 'GET_TRACK_FAILURE';

export const getPurgeTrackBegin = () => ({
  type: GET_TRACK_BEGIN,
});

export const getPurgeTrackSuccess = (track) => ({
  type: GET_TRACK_SUCCESS,
  payload: { track },
});

export const getPurgeTrackError = (error) => ({
  type: GET_TRACK_FAILURE,
  payload: { error },
});

export const getPurgeTrack = (
  trackId,
) => (dispatch) => {
  dispatch(getPurgeTrackBegin());

  return axios
    .get(`/api/tracks/${trackId}`)
    .then((response) => dispatch(getPurgeTrackSuccess(response.data)))
    .catch((error) => dispatch(getPurgeTrackError(error.response)));
};
