import {
  GET_TRACK_BEGIN,
  GET_TRACK_SUCCESS,
  GET_TRACK_FAILURE,
} from '../../actions/tracks/getPurgeTrack';
import createReducer from '../createReducer';

const initialState = {
  track: {},
  loading: true,
  error: null,
};

const getPurgeTrackBeginState = (state) => ({
  ...state,
  loading: true,
  error: null,
});

const getPurgeTrackSuccessState = (state, action) => ({
  ...state,
  loading: false,
  track: action.payload.track,
});

const getPurgeTrackFailureState = (state, action) => ({
  ...state,
  loading: false,
  error: action.payload.error,
});

const getPurgeTrack = createReducer(initialState, {
  [GET_TRACK_BEGIN]: getPurgeTrackBeginState,
  [GET_TRACK_SUCCESS]: getPurgeTrackSuccessState,
  [GET_TRACK_FAILURE]: getPurgeTrackFailureState,
});

export default getPurgeTrack;
