import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getTracks } from '../actions';
import LineChart from './LineChart';
import DoughnutChart from './DoughnutChart';
import BarChart from './BarChart';
import { COLORS } from '../constants';
import {
  getSongsAddedByMonth,
  getNumberOfAddsPerPerson,
  getArtists,
  getPlaylistDuration,
} from '../helpers';
import './PlaylistStatistics.scss';
import LoadingSpinner from './LoadingSpinner';

// TODO: look into this https://www.createwithdata.com/react-chartjs-dashboard/
const PlaylistStatistics = (props) => {
  const {
    playlist,
    loading,
    tracks,
    dispatch,
  } = props;

  const [numberOfSongsPerMonth, setNumberOfSongsPerMonth] = useState({});
  const [numberOfSongsPerPerson, setNumberOfSongsPerPerson] = useState({});
  const [top5Artists, setTop5Artists] = useState([]);

  useEffect(() => {
    dispatch(getTracks(playlist));
  }, [playlist]);

  useEffect(() => {
    if (!loading) {
      setNumberOfSongsPerMonth(getSongsAddedByMonth(tracks));
      setNumberOfSongsPerPerson(getNumberOfAddsPerPerson(tracks));
      setTop5Artists(getArtists(tracks));
    }
  }, [loading]);

  if (loading) {
    return (
      <LoadingSpinner />
    );
  }
  return (
    <div className="PlaylistStatistics__container">
      <h1 className="PlaylistStatistics__title">{playlist.name}</h1>
      <div>
        <p className="PlaylistStatistics__sub-info">
          Total songs:
          <span className="PlaylistStatistics__sub-info--stat">
            {' '}
            {playlist.tracks.total}
          </span>
        </p>
        <p className="PlaylistStatistics__sub-info">
          Playlist duration:
          <span className="PlaylistStatistics__sub-info--stat">
            {' '}
            {getPlaylistDuration(tracks)}
          </span>
        </p>
      </div>
      <div className="PlaylistStatistics__main PlaylistStatistics__chart-wrapper">
        <LineChart
          data={numberOfSongsPerMonth}
          dataSetLabel="Songs"
          title="Songs added per month"
          color="#383838"
        />
      </div>
      <div className="PlaylistStatistics__sub
                                PlaylistStatistics__chart-wrapper
                                PlaylistStatistics__first-sub-stat"
      >
        <DoughnutChart
          data={numberOfSongsPerPerson}
          title="Songs added per person"
          colors={COLORS}
        />
      </div>
      <div className="PlaylistStatistics__sub PlaylistStatistics__chart-wrapper">
        <BarChart
          data={top5Artists.map((artist) => artist[1])}
          labels={top5Artists.map((artist) => artist[0])}
          dataSetLabel="Songs"
          title="Top 5 artists"
          colors={COLORS}
        />
      </div>
    </div>
  );
};

PlaylistStatistics.propTypes = {
  dispatch: PropTypes.func.isRequired,
  playlist: PropTypes.shape({
    name: PropTypes.string,
    tracks: PropTypes.shape({
      total: PropTypes.number,
    }),
  }).isRequired,
  tracks: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.shape({}),
};

PlaylistStatistics.defaultProps = {
  error: null,
};

const mapStateToProps = (state) => ({
  tracks: state.tracks.tracks,
  loading: state.tracks.loading,
  error: state.tracks.error,
});

export default connect(mapStateToProps)(PlaylistStatistics);
