import React, { useEffect, useRef, useState } from 'react';
import Chart from 'chart.js';
import PropTypes from 'prop-types';

const BarChart = (props) => {
  const {
    title,
    labels,
    dataSetLabel,
    data,
    colors,
  } = props;

  const [chart, setChart] = useState(null);
  const chartRef = useRef(null);

  useEffect(() => {
    if (chart != null) {
      chart.destroy();
    }

    setChart(new Chart(chartRef.current, {
      type: 'bar',
      options: {
        scales: {
          xAxes: [{
            ticks: {
              fontColor: '#383838',
            },
          }],
          yAxes: [{
            ticks: {
              min: 0,
              stepSize: 1,
              fontColor: '#383838',
            },
          }],
        },
        legend: {
          display: false,
        },
        title: {
          display: true,
          text: title,
          fontSize: '15',
          fontColor: '#383838',
        },
        maintainAspectRatio: false,
      },
      data: {
        labels,
        datasets: [{
          label: dataSetLabel,
          data,
          backgroundColor: colors,
        }],
      },
    }));
  }, [data]);

  return <canvas ref={chartRef} />;
};

BarChart.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.number).isRequired,
  dataSetLabel: PropTypes.string.isRequired,
  labels: PropTypes.arrayOf(PropTypes.string).isRequired,
  colors: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default BarChart;
