import axios from 'axios';
import queryString from 'query-string';

export const DELETE_TRACKS_FROM_PLAYLIST_BEGIN = 'DELETE_TRACKS_FROM_PLAYLIST_BEGIN';
export const DELETE_TRACKS_FROM_PLAYLIST_SUCCESS = 'DELETE_TRACKS_FROM_PLAYLIST_SUCCESS';
export const DELETE_TRACKS_FROM_PLAYLIST_FAILURE = 'DELETE_TRACKS_FROM_PLAYLIST_FAILURE';

export const deleteTracksFromPlaylistBegin = () => ({
  type: DELETE_TRACKS_FROM_PLAYLIST_BEGIN,
});

export const deleteTracksFromPlaylistSuccess = (successMessage) => ({
  type: DELETE_TRACKS_FROM_PLAYLIST_SUCCESS,
  payload: { successMessage },
});

export const deleteTracksFromPlaylistError = (error) => ({
  type: DELETE_TRACKS_FROM_PLAYLIST_FAILURE,
  payload: { error },
});

export const deleteTracksFromPlaylist = (
  currentPurgeId,
  playlistId,
) => (dispatch) => {
  dispatch(deleteTracksFromPlaylistBegin());

  const parsed = queryString.parse(window.location.search);
  const accessToken = parsed.access_token;

  return axios
    .delete(`/api/execute/${currentPurgeId}/deleteFromPlaylist/${playlistId}`, {
      data: {}, // empty request body
      headers: { Authorization: `Bearer ${accessToken}` },
    })
    .then((response) => response.data)
    .then((successMessage) => dispatch(deleteTracksFromPlaylistSuccess(successMessage)))
    .catch((error) => {
      dispatch(deleteTracksFromPlaylistError(error.response));
    });
};
