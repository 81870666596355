import axios from 'axios';

export const ADD_VOTE_BEGIN = 'ADD_VOTE_BEGIN';
export const ADD_VOTE_SUCCESS = 'ADD_VOTE_SUCCESS';
export const ADD_VOTE_FAILURE = 'ADD_VOTE_FAILURE';

export const addVoteBegin = () => ({
  type: ADD_VOTE_BEGIN,
});

export const addVoteSuccess = (successMessage) => ({
  type: ADD_VOTE_SUCCESS,
  payload: { successMessage },
});

export const addVoteError = (error) => ({
  type: ADD_VOTE_FAILURE,
  payload: { error },
});

export const addVote = (
  purgeId,
  trackId,
  spotifyUserId,
  liked,
) => (dispatch) => {
  dispatch(addVoteBegin());

  return axios
    .post(`/api/purge/${purgeId}/${trackId}/vote`, {
      spotifyUserId,
      liked,
    })
    .then((response) => response.data)
    .then((successMessage) => dispatch(addVoteSuccess(successMessage)))
    .catch((error) => dispatch(addVoteError(error.response)));
};
