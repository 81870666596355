import axios from 'axios';
import queryString from 'query-string';

export const GET_CURRENT_USER_BEGIN = 'GET_CURRENT_USER_BEGIN';
export const GET_CURRENT_USER_SUCCESS = 'GET_CURRENT_USER_SUCCESS';
export const GET_CURRENT_USER_FAILURE = 'GET_CURRENT_USER_FAILURE';

export const getCurrentUserBegin = () => ({
  type: GET_CURRENT_USER_BEGIN,
});

export const getCurrentUserSuccess = (userData) => ({
  type: GET_CURRENT_USER_SUCCESS,
  payload: { userData },
});

export const getCurrentUserError = (error) => ({
  type: GET_CURRENT_USER_FAILURE,
  payload: { error },
});

export const getCurrentUser = () => (dispatch) => {
  dispatch(getCurrentUserBegin());

  const parsed = queryString.parse(window.location.search);
  const accessToken = parsed.access_token;

  return axios
    .get('/api/spotify/me', {
      headers: { Authorization: `Bearer ${accessToken}` },
    })
    .then((response) => response.data)
    .then((userData) => dispatch(getCurrentUserSuccess(userData)))
    .catch((error) => dispatch(getCurrentUserError(error.response)));
};
