import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import CloseIcon from '@material-ui/icons/Close';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DialogContentText from '@material-ui/core/DialogContentText';
import IconButton from '@material-ui/core/IconButton';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import HowToVoteIcon from '@material-ui/icons/HowToVote';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import LoadingSpinner from '../LoadingSpinner';
import { getPurgeTrack } from '../../actions/tracks/getPurgeTrack';
import usePrevious from '../../hooks/usePrevious';
import { NAME_MAPPINGS } from '../../helpers';
import './PurgeTracks.scss';
import './UserVotes.scss';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const UserVotes = (props) => {
  const {
    open,
    handleClose,
    trackId,
    purgeTrack,
    loading,
    dispatch,
  } = props;

  const prevOpenState = usePrevious(open);

  useEffect(() => {
    if (!prevOpenState && open) {
      dispatch(getPurgeTrack(trackId));
    }
  }, [open]);

  const getSpotifyUser = (spotifyUserId) => (NAME_MAPPINGS[spotifyUserId]
    ? NAME_MAPPINGS[spotifyUserId] : spotifyUserId);

  return (
    <div>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        scroll="paper"
        fullWidth
        PaperProps={{
          style: {
            textAlign: 'center',
            backgroundColor: '#2c154a',
            color: '#ff1db7',
          },
        }}
        TransitionComponent={Transition}
      >
        <DialogTitle>Votes</DialogTitle>
        <IconButton onClick={handleClose} className="PurgeTracks__close-btn">
          <CloseIcon />
        </IconButton>
        <DialogContent className="PurgeTracks__content">
          {!loading ? (
            <DialogContentText className="PurgeTracks__subtext UserVotes__track-name-container">
              <img className="UserVotes__track-img" variant="square" alt={purgeTrack.name} src={purgeTrack.albumImage} />
              {purgeTrack.name}
            </DialogContentText>
          ) : null}
          {loading ? (
            <LoadingSpinner />
          ) : [
            purgeTrack.votes.length > 0 ? (
              <List key={purgeTrack._id}>
                {purgeTrack.votes.map((vote) => (
                  <ListItem key={vote._id} className="PurgeTracks__list-item">
                    <ListItemIcon>
                      <HowToVoteIcon className="PurgeTracks__vote-btn" />
                    </ListItemIcon>
                    <ListItemText
                      className="PurgeTracks__track-name"
                      primary={getSpotifyUser(vote.spotifyUserId)}
                    />
                    <ListItemSecondaryAction>
                      {vote.liked ? (
                        <ThumbUpIcon style={{ color: 'green' }} />
                      ) : (
                        <ThumbDownIcon style={{ color: 'red' }} />
                      )}
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
            ) : [
              <div key={purgeTrack._id}>
                <h2>No votes cast</h2>
              </div>,
            ],
          ]}
        </DialogContent>
      </Dialog>
    </div>
  );
};

UserVotes.propTypes = {
  dispatch: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  trackId: PropTypes.string.isRequired,
  // from redux
  purgeTrack: PropTypes.shape({
    _id: PropTypes.string,
    name: PropTypes.string,
    albumImage: PropTypes.string,
    votes: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  loading: PropTypes.bool,
  error: PropTypes.shape({}),
};

UserVotes.defaultProps = {
  purgeTrack: {},
  loading: false,
  error: null,
};

const mapStateToProps = (state) => ({
  purgeTrack: state.purgeTrack.track,
  loading: state.purgeTrack.loading,
  error: state.purgeTrack.error,
});

export default connect(mapStateToProps)(UserVotes);
