import axios from 'axios';
import queryString from 'query-string';

export const ADD_TRACKS_BEGIN = 'ADD_TRACKS_BEGIN';
export const ADD_TRACKS_SUCCESS = 'ADD_TRACKS_SUCCESS';
export const ADD_TRACKS_FAILURE = 'ADD_TRACKS_FAILURE';

export const addTracksBegin = () => ({
  type: ADD_TRACKS_BEGIN,
});

export const addTracksSuccess = (successMessage) => ({
  type: ADD_TRACKS_SUCCESS,
  payload: { successMessage },
});

export const addTracksError = (error) => ({
  type: ADD_TRACKS_FAILURE,
  payload: { error },
});

export const addTracks = (
  currentPurgeId,
  playlistId,
) => (dispatch) => {
  dispatch(addTracksBegin());

  const parsed = queryString.parse(window.location.search);
  const accessToken = parsed.access_token;

  return axios
    .post(`/api/purge/${currentPurgeId}/${playlistId}/tracks/sinceLastPurge`,
      {}, // empty request body
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      })
    .then((response) => response.data)
    .then((successMessage) => dispatch(addTracksSuccess(successMessage)))
    .catch((error) => dispatch(addTracksError(error.response)));
};
