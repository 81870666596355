const COLORS = [
  '#e6194B', '#3cb44b',
  '#ffe119', '#4363d8',
  '#f58231', '#911eb4',
  '#42d4f4', '#f032e6',
  '#bfef45', '#fabebe',
  '#469990', '#e6beff',
];

const ACTIVE_PLAYLIST = '7ySQtq3A6SZbTglbgmkVX5';

export {
  COLORS,
  ACTIVE_PLAYLIST,
};
