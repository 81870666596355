import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getPlaylists } from '../actions';
import { getCurrentUser } from '../actions/spotify/getCurrentUser';
import Playlist from './Playlist';
import './Playlists.scss';
import PlaylistStatistics from './PlaylistStatistics';
import LoadingSpinner from './LoadingSpinner';
import BurgerMenu from './BurgerMenu';
import SignInButton from './SignInButton';
import Search from './Search';

function Playlists({ ...props }) {
  const [areStatisticsVisible, setStatisticsVisible] = useState(false);
  const [playlistForStatistics, setPlaylistForStatistics] = useState(null);

  // on mount
  useEffect(() => {
    props.dispatch(getPlaylists());
    props.dispatch(getCurrentUser());
  }, []);

  const handlePlaylistClick = (playlist) => {
    setPlaylistForStatistics(playlist);
    setStatisticsVisible(true);
    document.getElementById('App').style.background = 'linear-gradient(#2c154a, #3a205b, #ff1db7)';
  };

  if (props.loading) {
    return (
      <LoadingSpinner />
    );
  } if (props.error) {
    return (
      <SignInButton />
    );
  }
  const albums = props.playlists
    .map((playlist) => (
      <Playlist
        key={playlist.id}
        playlist={playlist}
        onClick={() => handlePlaylistClick(playlist)}
      />
    ));

  const playlistSelector = (
    <div>
      <Search playlists={props.playlists} />
      <BurgerMenu
        playlists={props.playlists}
        handlePlaylistChange={handlePlaylistClick}
        handleHomeClick={() => setStatisticsVisible(false)}
      />
      <h1 className="Playlists__title">The SummerVibes Collection</h1>
      <h2 className="Playlists__subtitle">Select a playlist</h2>
      <div className="Playlists__container">
        {albums}
      </div>
    </div>
  );

  const playlistStatistics = (
    <div>
      <BurgerMenu
        playlists={props.playlists}
        handlePlaylistChange={handlePlaylistClick}
        handleHomeClick={() => setStatisticsVisible(false)}
      />
      <PlaylistStatistics
        playlist={playlistForStatistics}
      />
    </div>
  );

  return areStatisticsVisible ? (playlistStatistics) : (playlistSelector);
}

Playlists.propTypes = {
  dispatch: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  error: PropTypes.shape({}),
  playlists: PropTypes.arrayOf(PropTypes.shape()),
};

Playlists.defaultProps = {
  playlists: [],
  loading: false,
  error: null,
};

const mapStateToProps = (state) => ({
  playlists: state.playlists.playlists,
  loading: state.playlists.loading,
  error: state.playlists.error,
});

export default connect(mapStateToProps)(Playlists);
