import axios from 'axios';

export const CREATE_PURGE_BEGIN = 'CREATE_PURGE_BEGIN';
export const CREATE_PURGE_SUCCESS = 'CREATE_PURGE_SUCCESS';
export const CREATE_PURGE_FAILURE = 'CREATE_PURGE_FAILURE';

export const createPurgeBegin = () => ({
  type: CREATE_PURGE_BEGIN,
});

export const createPurgeSuccess = (purgeData) => ({
  type: CREATE_PURGE_SUCCESS,
  payload: { purgeData },
});

export const createPurgeError = (error) => ({
  type: CREATE_PURGE_FAILURE,
  payload: { error },
});

export const createPurge = (
  playlistId,
  name,
  date,
) => (dispatch) => {
  dispatch(createPurgeBegin());

  return axios
    .post('/api/purge', {
      playlistId,
      name,
      date,
    })
    .then((response) => response.data)
    .then((purgeData) => dispatch(createPurgeSuccess(purgeData)))
    .catch((error) => dispatch(createPurgeError(error.response)));
};
