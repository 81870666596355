import React from 'react';
import PropTypes from 'prop-types';
import './Playlist.scss';

const Playlist = (props) => {
  const {
    onClick,
    playlist,
  } = props;

  // TODO: fix these eslint disabled rules
  return (
    <div className="Playlist__container">
      <img // eslint-disable-line
        className="Playlist__album-cover"
        onClick={onClick}
        src={playlist.images[0].url}
        alt={playlist.name}
      />
    </div>
  );
};

Playlist.propTypes = {
  playlist: PropTypes.shape({
    images: PropTypes.arrayOf(PropTypes.shape()),
    name: PropTypes.string,
  }).isRequired,
  onClick: PropTypes.func.isRequired,
};

export default Playlist;
