import React from 'react';
import './SignInButton.scss';
import './Playlists.scss';
import { backendUrl } from '../config';

const SignInButton = () => {
  const redirectOnSignIn = () => {
    window.location = `${backendUrl}/api/login`;
  };

  return (
    <div>
      <h1 className="Playlists__title">The SummerVibes Collection</h1>
      <div className="SignIn__container">
        <button
          className="SignIn__button"
          onClick={() => redirectOnSignIn()}
        >
          <span>
            Sign in with Spotify
          </span>
        </button>
      </div>
    </div>
  );
};

export default SignInButton;
