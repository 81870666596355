import {
  DELETE_TRACKS_FROM_PLAYLIST_BEGIN,
  DELETE_TRACKS_FROM_PLAYLIST_SUCCESS,
  DELETE_TRACKS_FROM_PLAYLIST_FAILURE,
} from '../../actions/execution/deleteTracksFromPlaylist';
import createReducer from '../createReducer';

const initialState = {
  successMessage: null,
  loading: false,
  error: null,
};

const deleteTracksFromPlaylistBeginState = (state) => ({
  ...state,
  loading: true,
  error: null,
});

const deleteTracksFromPlaylistSuccessState = (state, action) => ({
  ...state,
  loading: false,
  successMessage: action.payload.successMessage,
});

const deleteTracksFromPlaylistErrorState = (state, action) => ({
  ...state,
  loading: false,
  error: action.payload.error,
});

const addTracks = createReducer(initialState, {
  [DELETE_TRACKS_FROM_PLAYLIST_BEGIN]: deleteTracksFromPlaylistBeginState,
  [DELETE_TRACKS_FROM_PLAYLIST_SUCCESS]: deleteTracksFromPlaylistSuccessState,
  [DELETE_TRACKS_FROM_PLAYLIST_FAILURE]: deleteTracksFromPlaylistErrorState,
});

export default addTracks;
