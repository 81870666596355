import React, { useState } from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import WhatshotIcon from '@material-ui/icons/Whatshot';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import HomeIcon from '@material-ui/icons/Home';
import AlbumIcon from '@material-ui/icons/Album';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import Purge from './purge/Purge';
import './BurgerMenu.scss';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    background: '#2c154a',
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
}));

const BurgerMenu = (props) => {
  const [openPurgeTracks, setOpenPurgeTracks] = useState(false);
  const classes = useStyles();

  const {
    handleHomeClick,
    handlePlaylistChange,
    playlists,
  } = props;

  const [isOpen, setIsOpen] = useState(false);

  const handleDrawerOpen = () => {
    setIsOpen(true);
  };

  const handleDrawerClose = () => {
    setIsOpen(false);
  };

  const handleHome = () => {
    setIsOpen(false);
    handleHomeClick();
  };

  const handleItemClick = (playlist) => {
    setIsOpen(false);
    handlePlaylistChange(playlist);
  };

  return (
    <div>
      <IconButton
        aria-label="open drawer"
        onClick={handleDrawerOpen}
        className={`BurgerMenu ${isOpen ? classes.hide : ''}`}
      >
        <MenuIcon fontSize="large" />
      </IconButton>
      <Drawer
        className={classes.drawer}
        anchor="left"
        open={isOpen}
        onClose={handleDrawerClose}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton
            className="BurgerMenu__close-btn"
            onClick={handleDrawerClose}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <List>
          <ListItem className="BurgerMenu__list-item" button key="Home" onClick={handleHome}>
            <ListItemIcon className="BurgerMenu__list-item-icon"><HomeIcon /></ListItemIcon>
            <ListItemText primary="Home" />
          </ListItem>
          {playlists.map((playlist) => (
            <ListItem
              className="BurgerMenu__list-item"
              button
              key={playlist.id}
              onClick={() => handleItemClick(playlist)}
            >
              <ListItemIcon className="BurgerMenu__list-item-icon"><AlbumIcon /></ListItemIcon>
              <ListItemText primary={playlist.name} />
            </ListItem>
          ))}
          <Divider className="BurgerMenu__divider" variant="middle" />
          <ListItem className="BurgerMenu__list-item" button key="Purge" onClick={() => setOpenPurgeTracks(true)}>
            <ListItemIcon className="BurgerMenu__list-item-icon"><WhatshotIcon /></ListItemIcon>
            <ListItemText primary="Purge" />
          </ListItem>
        </List>
      </Drawer>
      <Purge open={openPurgeTracks} handleClose={() => setOpenPurgeTracks(false)} />
    </div>
  );
};

BurgerMenu.propTypes = {
  handleHomeClick: PropTypes.func.isRequired,
  handlePlaylistChange: PropTypes.func.isRequired,
  playlists: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default BurgerMenu;
