import {
  GET_TRACKS_BEGIN,
  GET_TRACKS_SUCCESS,
  GET_TRACKS_FAILURE,
} from '../actions';

const initialState = {
  tracks: [],
  loading: true,
  error: null,
};

const getTracks = (state = initialState, action) => {
  switch (action.type) {
    case GET_TRACKS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_TRACKS_SUCCESS:
      return {
        ...state,
        loading: false,
        tracks: action.payload.tracks,
      };
    case GET_TRACKS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error.message,
        tracks: [],
      };
    default:
      return state;
  }
};

export default getTracks;
